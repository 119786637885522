import React from 'react';
import { useRouter } from 'next/router';
//image
import Image from 'next/image';
import Logo from '../../../../../public/images/Logowhite.png';
import appstore from '../../../../../public/landingimages/App store.svg';
import gpay from '../../../../../public/landingimages/gpay.svg';
//styles
import styles from './footer_desktop.module.scss';
import classNames from 'classnames';

const Footer_desktop = () => {
    const router = useRouter();

    const handlePlaystore = ()=>{
        // router.push('https://play.google.com/store/apps/details?id=com.turftown&hl=en_US&pli=1')
        window.open('https://play.google.com/store/apps/details?id=com.turftown', '_blank')
    }
    const handleApplestore = ()=>{
        window.open('https://apps.apple.com/in/app/turf-town/id1490231308', '_blank')
    }

    const handleContactUs = () =>{
        router.push('/contact')
    }

    const handleTermsofService = () =>{
        router.push('/termsofservice')
    }

    const handlePrivacy = () =>{
        router.push('/privacy-policy')
    }
    const handleInstagram = () =>{
        window.open('https://www.instagram.com/turftown.in/?hl=en', '_blank')
    }
    const handleTwitter = () =>{
        window.open('https://twitter.com/TurfTownOffl', '_blank')
    }
    const handleLinkedIn = () =>{
        window.open('https://www.linkedin.com/company/turf-town/', '_blank')
    }
    return (
        <div>
            <div className={styles.footer_desktop_background}>
        <div className={styles.row}>
            <div className={classNames(styles.col_1)}>
            </div>
            <div className={classNames(styles.col_10)}>
                <div className={styles.row}>
                    <div className={classNames(styles.col_6)}>
                        <div className={styles.footer_desktop_left}>
                            <a href='/' aria-label='Read more about Seminole tax hike'> 
                              <Image className={styles.footer_desktop_turf_logo} src={Logo} alt='logo' /> </a>
                            <div className={styles.footer_desktop_app_icons}>
                              <Image src={appstore} className={styles.desktop_apple_icon} alt='logo' loading='lazy' onClick={handleApplestore}/>                   
                             <Image className={styles.desktop_gpay_icon} src={gpay} alt='logo' loading='lazy' onClick={handlePlaystore}/> </div>
                             <p className={styles.iconic_text} style={{ fontFamily: "Nexa-Regular"}}>Designed for sport & made in Madras</p> 
                        </div>
                    </div>
                    <div className={classNames(styles.col_6)}>
                        <div className={styles.row}>
                            <div className={classNames(styles.col_6)}>
                                <div className={classNames(styles.footer_company_desktop)}>
                                    <div className={classNames(styles.company_desktop_title)}>Company</div>                                 
                                   <div className={styles.footer_desktop_content} style={{ fontFamily: "Nexa-TextBook", color: '#C7C7C7' }} onClick={handleContactUs}>Contact Us</div>
                                   <div className={styles.footer_desktop_content} style={{ fontFamily: "Nexa-TextBook", color: '#C7C7C7' }} onClick={handleTermsofService}>Terms of Service</div>
                                 <div className={styles.footer_desktop_content} style={{ fontFamily: "Nexa-TextBook", color: '#C7C7C7' }} onClick={handlePrivacy}>Privacy Policy</div>
                                </div>
                            </div>
                            <div className={classNames(styles.col_6)}>
                                <div className={classNames(styles.footer_socal_desktop)}>
                                    <div className={classNames(styles.company_desktop_title)}>Social</div>
                              <div className={styles.footer_desktop_content} style={{ fontFamily: "Nexa-TextBook", color: '#C7C7C7' }}
                              onClick={handleInstagram}>Instagram</div>
                                  <div className={styles.footer_desktop_content}style={{ fontFamily: "Nexa-TextBook", color: '#C7C7C7' }}
                                  onClick={handleTwitter}>Twitter</div>
                              <div className={styles.footer_desktop_content} style={{ fontFamily: "Nexa-TextBook", color: '#C7C7C7' }}
                              onClick={handleLinkedIn}>LinkedIn</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classNames(styles.col_1)}>
            </div>
        </div>
        </div>
    </div>
    )
}

export default Footer_desktop